const shipmentData = [
  { id: 1, name: "Rail Shipmentment", value: "rail_sh" },
  { id: 2, name: "Land Shipmentment", value: "land_sh" },
  { id: 1, name: "Air Shipmentment", value: "air_sh" },
  { id: 1, name: "Water Shipmentment", value: "water_sh" },
  { id: 1, name: "Rail Shipmentment", value: "rail_sh" },
];

const locationData = [
  { id: 1, name: "Lagos", value: "lag" },
  { id: 2, name: "Ibadan", value: "ibd" },
  { id: 1, name: "Abuja", value: "abj" },
  { id: 1, name: "Porthacourt", value: "ph" },
  { id: 1, name: "Imo", value: "imo" },
];

export { shipmentData, locationData };
