export const tableDetails = [
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Groceries",
    status: "Completed",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Groceries",
    status: "Cancelled",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Groceries",
    status: "Pending",
  },
]
