import Button from "../../components/shared-components/Button"
import { useState } from "react"
import RenderSecurity from "./renderSecurity"

const Security = () => {
  const [passwordDetails, setPasswordDetails] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  })
  const handleChange = (e) => {
    setPasswordDetails({ ...passwordDetails, [e.target.name]: e.target.value })
  }

  return (
    <div className="">
      <form className="w-full md:w-[45%] space-y-[1.5rem] ">
        {Object.entries(passwordDetails).map(([field, value], i) => (
          <div key={i}>{RenderSecurity(field, value, handleChange)}</div>
        ))}
        <div>
          <Button text={"Change password"} className={"w-full text-white "} />
        </div>
      </form>
    </div>
  )
}

export default Security
