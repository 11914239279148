import React, { useState } from "react"
import Header from "../Header"
import Footer from "../Footer"
import SideBar from "../SideBar"
import {
  dashboardPathName,
  settingsPathName,
  shipmentPathName,
} from "../routes/pathName"
import DashboardIcon from "./sideIcons/DashboardIcon"
import ShipmentIcon from "./sideIcons/ShipmentIcon"
import SettingsIcon from "./sideIcons/SettingsIcon"

const Container = ({ children }) => {
  const [toggle, setToggle] = useState(false)
  const [active, setActive] = useState(0)

  const sideBarDetails = [
    {
      title: "Dashboard",
      path: dashboardPathName,
      icon: DashboardIcon,
    },
    {
      title: "Shipments",
      path: shipmentPathName,
      icon: ShipmentIcon,
    },
    {
      title: "Settings",
      path: settingsPathName,
      icon: SettingsIcon,
    },
  ]

  function handleToggle() {
    setToggle(!toggle)
  }
  function handleActive(index) {
    setActive(index)
  }

  return (
    <div className="font-montserrat bg-white grid grid-cols-1 md:grid-cols-[18%_1fr] grid-rows-[auto,1fr,auto] w-full min-h-screen ">
      <div
        className={`col row-span-3 ${toggle ? "block" : "hidden"} md:block `}
      >
        <SideBar
          sideBarDetails={sideBarDetails}
          active={active}
          handleActive={handleActive}
          handleToggle={handleToggle}
        />
      </div>
      <div className="row-span-1 px-[2rem] py-[1rem] drop-shadow-xl bg-white fixed w-full md:w-[calc(100%-18%)] md:left-[18%] z-40">
        <Header
          sideBarDetails={sideBarDetails}
          active={active}
          handleToggle={handleToggle}
        />
      </div>
      <div className="row-span-1 mt-[5rem]  p-[2rem] ">{children}</div>
      <div className="row-span-1 px-[2rem] ">
        <Footer />
      </div>
    </div>
  )
}

export default Container
