import { useState } from "react"
import Button from "../../../components/shared-components/Button"
import RenderInput from "../renderInput"
import { Link, useNavigate } from "react-router-dom"
import {
  loginPathName,
  verifyPathName,
} from "../../../components/routes/pathName"

const SignUpForm = () => {
  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    AccountType: "",
    Surname: "",
    OtherName: "",
    Phone: "",
    Password: "",
  })

  const accountTypeDetails = ["Individual", "Business"]

  const handleChange = (value, event, fieldName) => {
    if (event) {
      setFormData({ ...formData, [event.target.name]: event.target.value })
    } else if (fieldName) {
      setFormData({ ...formData, [fieldName]: value })
    }
  }

  function submit(e) {
    e.preventDefault()
    navigate(verifyPathName)
  }

  return (
    <form className="w-full space-y-[1.2rem]" onSubmit={submit}>
      <h1 className="text-[2.5rem] font-bold">Sign Up</h1>
      <div>
        <div className="space-y-[1.2rem]">
          {Object.entries(formData).map(([field, value], i) => (
            <div key={i}>
              {RenderInput(field, value, handleChange, accountTypeDetails)}
            </div>
          ))}
        </div>
        <div className=" mt-[0.5rem] ">
          <p className="text-[0.9rem]  w-[17.5rem] cursor-pointer">
            By proceeding, you agree to our{" "}
            <span className="text-primary-300 font-semibold ">
              Terms & Conditions
            </span>{" "}
            and{" "}
            <span className="text-primary-300 font-semibold ">
              Privacy policy
            </span>
          </p>
        </div>
      </div>
      <div>
        <Button
          type="submit"
          text={"Sign up"}
          className={"text-white w-full"}
        />
        <div className="">
          <p className="text-[0.9rem]">
            Already have an account?{" "}
            <Link
              to={loginPathName}
              className="text-primary-300 font-bold cursor-pointer"
            >
              Login
            </Link>
          </p>
        </div>
      </div>
    </form>
  )
}

export default SignUpForm
