import React from "react"
import QuoteInput from "./index"
import date from "../../../../assets/images/date.svg"
const renderInput = (field, value, i, handleInputChange) => {
  const placeholder = (field) => {
    switch (field) {
      case "Origin":
        return "City or Zip code"
      case "Cargo":
        return "Cargo Details"
      case "Destination":
        return "City or Zip code"
      case "Date":
        return "Select Date"
      default:
        return ""
    }
  }

  if (field === "Date") {
    return (
      <div className="relative">
        <QuoteInput
          label={field}
          htmlFor={field}
          id={field}
          value={value}
          name={field}
          type="date"
          placeholder={placeholder(field)}
          onChange={handleInputChange}
        />
        <div className="absolute top-[1.25rem] right-[1rem] ">
          <img src={date} alt="logo" className="max-w-full" />
        </div>
      </div>
    )
  } else {
    return (
      <QuoteInput
        key={i}
        label={field}
        htmlFor={field}
        id={field}
        value={value}
        name={field}
        placeholder={placeholder(field)}
        onChange={handleInputChange}
      />
    )
  }
}

export default renderInput
