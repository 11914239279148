import React from "react"
import ProfileInput from "./ProfileInput"

const renderProfile = (field, value, handleProfile, businessData) => {
  if (field === "businessType") {
    return (
      <select
        onChange={handleProfile}
        className="w-full rounded-[0.4rem] bg-[#F9F9F9] pt-[1.5rem] pb-[0.5rem] px-[1rem] border border-white  outline-none"
        name={field}
        value={value}
      >
        {businessData.map((data, i) => (
          <option key={i} value={data}>
            {data}
          </option>
        ))}
      </select>
    )
  } else {
    return (
      <ProfileInput
        placeholder={field}
        value={value}
        name={field}
        type={field === "phone" ? "tel" : field === "email" ? "email" : "text"}
        onChange={handleProfile}
        label={field}
      />
    )
  }
}

export default renderProfile
