import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { pageRoute } from './components/routes';
import Container from './components/Container';


function App(){
  return (
    <BrowserRouter>
      <Routes>
        {
          pageRoute.map((data, i) => (
            <Route key={i} path={data.path} element={
             data.layout ? 
             ( 
             <Container>
              <data.element />
            </Container>) :  (<data.element />)
            }/>
          ))
        }
      </Routes>
    </BrowserRouter>
  );
}


export default App;
