import { useState } from "react"
import Button from "../../../components/shared-components/Button"
import RenderInput from "../renderInput"
import { Link, useNavigate } from "react-router-dom"
import {
  dashboardPathName,
  passwordResetPathName,
  signUpPathName,
} from "../../../components/routes/pathName"

const LoginForm = () => {
  const navgate = useNavigate()
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  function submit(e) {
    e.preventDefault()
    navgate(dashboardPathName)
  }

  return (
    <form className="w-full space-y-[1.2rem]">
      <p className="text-[1.25rem] font-light ">Welcome back!</p>
      <h1 className="text-[2.5rem] font-bold ">Login</h1>
      {Object.entries(formData).map(([field, value], i) => (
        <div key={i}>{RenderInput(field, value, handleChange)}</div>
      ))}
      <div className="flex justify-end mt-0 ">
        <Link
          to={passwordResetPathName}
          className="text-[0.9rem] text-primary-300 font-bold cursor-pointer"
        >
          Forgot password?
        </Link>
      </div>
      <div>
        <Button
          onClick={submit}
          text={"Login"}
          className={"text-white w-full "}
        />
        <div className="mt-[1.4rem] ">
          <p className="text-[0.9rem] ">
            Don’t have an account?{" "}
            <Link
              to={signUpPathName}
              className="text-primary-300 font-bold cursor-pointer"
            >
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </form>
  )
}

export default LoginForm
