import { CalenderSvg, ClockSvg } from "../../../assets/images";
import { locationData } from "../../../components/data/optionData";
import Button from "../../../components/shared-components/Button";
import InputFields from "../../../components/shared-components/input-fields";
import Select from "../../../components/shared-components/select/Select";
import CloseComponent from "./close-component";
import Heading from "./headings";
import ToogleThroughGroup from "./toogle-through-group";

const Schedule = () => {
  return (
    <>


        <div>
          <div className="block sm:flex gap-[30px] justify-between items-center pb-[40px] ">
            <div className="w-full">
              <InputFields
                pText={"Pickup details"}
                htmlFor={"Time"}
                label={"Time"}
                type={"text"}
                name={"phone_no"}
                className={``}
                placeholder={"Select"}
                img={ClockSvg}
                dateReff={""}
              />

              <InputFields
                htmlFor={"Date"}
                label={"Date"}
                type={"date"}
                name={"phone_no"}
                className={``}
                placeholder={"Select"}
                img={CalenderSvg}
                conClassName={"my-[80px]"}
              />
            </div>

            <div className="w-full">
              <Select
                selectName={""}
                htmlFor={""}
                label={"Service Options"}
                selectValue={""}
                datas={locationData}
                pText={"Package Details"}
              />

              <InputFields
                htmlFor={"Additional Note"}
                label={"Additional Note"}
                type={"text"}
                name={"phone_no"}
                className={``}
                placeholder={"Enter here"}
              />
            </div>
          </div>

          <div className="flex gap-[30px] justify-between items-center pb-[40px] "></div>

          <div className="flex flex-wrap gap-[20px] pb-[40px]">
            <Button text={"Save & Continue"} className={"text-white"} />
            <Button
              text={"Cancel"}
              className={"text-[20px] !text-[#FF2424] !bg-[#FFD4D4] "}
            />
          </div>
        </div>
    </>
  );
};

export default Schedule;
