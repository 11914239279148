import React, { useState } from "react"
import Table from "../../../components/shared-components/Table"
import TBody from "../../../components/shared-components/Table/TBody"
import { shipmentDetails } from "../data"
import { PiDotsThreeVerticalBold } from "react-icons/pi"
import { Link } from "react-router-dom"
import { shipmentPathName } from "../../../components/routes/pathName"

const filterShipments = (status) => {
  if (status === "All") return shipmentDetails
  return shipmentDetails.filter((data) => data.status === status)
}

const ShipmentTable = ({ status }) => {
  const result = filterShipments(status)

  const [activeRow, setActiveRow] = useState(null)

  const handleToggleView = (id) => {
    setActiveRow((prevActiveRow) => (prevActiveRow === id ? null : id))
  }

  return (
    <div className="w-full overflow-x-auto">
      <Table
        datas={["Name", "Shipment ID", "Amount", "Date", "Type", "Status", " "]}
      >
        <TBody
          tableDatas={result}
          renderCustomContent={(detail, index) => (
            <div className="relative">
              <div>
                <button
                  className="text-blue-500"
                  onClick={() => handleToggleView(index)}
                >
                  <PiDotsThreeVerticalBold />
                </button>
              </div>
              <ul
                className={`bg-white z-20 text-[#4A515C] text-[0.75rem] space-y-3 ${
                  activeRow === index ? "block" : "hidden"
                } text-center w-[8rem] p-[0.8rem] rounded-[0.8rem] shadow-xl ${
                  result.length - 1 === index || result.length - 2 === index
                    ? "bottom-6 "
                    : null
                } absolute left-[-6rem]`}
              >
                <li>
                  <Link to={`${shipmentPathName}/${index}`}>View</Link>
                </li>
                <li>Share</li>
                <li>Download invoice</li>
                <li className="cursor-pointer" onClick={()=>setActiveRow(false)} >Cancel</li>
              </ul>
            </div>
          )}
        />
      </Table>
    </div>
  )
}

export default ShipmentTable
