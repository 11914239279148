const InputFields = ({
  type,
  name,
  id,
  className,
  htmlFor,
  label,
  placeholder,
  pText,
  img,
  divClassName,
  dateReff,
  ...props
}) => {


  return (
    <>
      <div className={`w-full py-[20px] `}>
        {pText && (
          <p className="text-[#979797] pb-[10px] text-[16px] font-[montserrat] leading-[23.05px]">
            {pText}
          </p>
        )}

        {
          <div
            className={`py-[12px] px-[15px] w-full rounded-[10.39px] bg-white ${
              img && "flex"
            } ${divClassName}  `}
          >
            <div className="w-full items-center">
              <label
                htmlFor={htmlFor}
                className="text-[10.85px] text-[#E2E2E2]"
              >
                {label}
              </label>
              <input
                type={type}
                name={name}
                id={id}
                className={`w-[100%] text-[16.27px] outline-none text-[#777777] ${className}`}
                placeholder={placeholder}
              />
            </div>

            {img && <img src={img} alt="" />}
          </div>
        }
      </div>
    </>
  );
};

export default InputFields;
