import React, { useState } from "react"
import upload from "../../assets/images/profileImg.png"
import { IoIosCamera } from "react-icons/io"
import renderProfile from "./renderProfile"
import Button from "../../components/shared-components/Button"

const Profile = () => {
  const [profileDetails, setProfileDetails] = useState({
    businessType: "",
    name: "",
    email: "",
    phone: "",
  })

  const businessData = ["Business", "Individual"]

  function handleProfile(e) {
    setProfileDetails({ ...profileDetails, [e.target.name]: e.target.value })
  }

  return (
    <div className="flex flex-col md:flex-row gap-[2rem] ">
      <div className="flex justify-center items-center w-[7rem] h-[7.5rem] bg-primary-400 rounded-[0.3rem] relative ">
        <img src={upload} alt="upload" className="max-w-full" />
        <div className="flex justify-center items-center bg-[#EBEBEB] bottom-5 right-2 rounded-full p-1 absolute">
          <IoIosCamera className="text-primary-300 " />
        </div>
      </div>
      <form className="w-full md:w-[45%] space-y-[1.5rem] ">
        {Object.entries(profileDetails).map(([field, value], i) => (
          <div key={i}>
            {renderProfile(field, value, handleProfile, businessData)}
          </div>
        ))}

        <div>
          <Button text={"Save changes"} className={"w-full text-white "} />
        </div>
      </form>
    </div>
  )
}

export default Profile
