const QuoteInput = ({ label,htmlFor, ...props }) => {
  return (
    <div className="relative mt-[0.75rem]">
      <label htmlFor={htmlFor} className="text-[0.7rem] font-medium absolute top-3 left-4 ">
        {label}
      </label>
      <input
        type="text"
        {...props}
        className="w-full bg-white rounded-[0.4rem] pt-[2rem] pb-[1rem] px-[1rem] border border-white  outline-none"
      />
    </div>
  )
}

export default QuoteInput
