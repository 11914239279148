import React from "react"
import InputField from "../../components/shared-components/Input"
import Button from "../../components/shared-components/Button"
import { loginPathName, newPasswordResetPathName } from "../../components/routes/pathName"
import { Link } from "react-router-dom"
import PasswordResetLayout from "./PasswordResetLayout"

const PasswordReset = () => {
  return (
    <PasswordResetLayout
      word={true}
      instruction={"Enter your email address and we’ll send you a reset link"}
    >
      <form className="mt-[2rem] space-y-[2rem] ">
        <InputField
          placeholder="Enter Email"
          label="Email"
          className={"bg-[#F7F7F7] "}
        />
        <div className="">
          <Link to={newPasswordResetPathName}>
          <Button text={"Request link"} className={"text-white w-full"} />
          </Link>
          <div className="mt-[1.4rem] ">
            <p className="text-[0.9rem] ">
              Was this request a mistake?{" "}
              <Link
                to={loginPathName}
                className="text-primary-300 font-bold cursor-pointer"
              >
                Back to login
              </Link>
            </p>
          </div>
        </div>
      </form>
    </PasswordResetLayout>
  )
}

export default PasswordReset
