import React from "react"

const TBody = ({ tableDatas, renderCustomContent }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Completed":
        return "text-[#2DB933]"
      case "Cancelled":
        return "text-[#FF0000]"
      case "Pending":
        return "text-[#FFBB00]"
      default:
        return ""
    }
  }

  return (
    <tbody>
      {(tableDatas || []).map((detail, index) => (
        <tr
          key={index}
          className={`text-center ${
            index % 2 === 0 ? "bg-[#F9F9F9]" : "bg-white"
          }`}
        >
          {Object.values(detail).map((data, i) => (
            <td
              key={i}
              className={`py-[1rem] w-fit ${
                i === 5
                  ? `${getStatusColor(data)} font-semibold`
                  : "font-light text-[#2A2A2A] text-[1rem]"
              }`}
            >
              {i === 2 ? (
                <span dangerouslySetInnerHTML={{ __html: data }} />
              ) : (
                data
              )}
            </td>
          ))}
            {renderCustomContent && (
            <td className="py-[1rem] w-fit">
              {renderCustomContent(detail, index)}
            </td>
          )}
        </tr>
      ))}
    </tbody>
  )
}

export default TBody
