const Button = ({ className, text, icon, image, ...props }) => {
  return (
    <button
      {...props}
      className={`flex justify-center text-[1rem] lg:text-[20px] font-medium py-[16px] px-[0.6rem] sm:px-[42.5px] items-center gap-5 rounded-[8px] w-fit bg-primary-300 cursor-pointer  ${className}`}
    >
      {image ? <img src={image} alt="" /> : icon ? icon : null} {text}
    </button>
  )
}

export default Button
