export const shipmentDetails = [
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Air freight",
    status: "Completed",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Air freight",
    status: "Pending",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Air freight",
    status: "Completed",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Air freight",
    status: "Cancelled",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Air freight",
    status: "Completed",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Sea freight",
    status: "Pending",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Sea freight",
    status: "Completed",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Sea freight",
    status: "Pending",
  },
  {
    names: "Oladapo Koiki",
    shipment_ID: "4654746823",
    amount: "&#8358;456,090",
    date: "Mar. 20, 2023",
    type: "Sea freight",
    status: "Completed",
  },
]
