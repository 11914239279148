import React from "react"
import zulfah from "../../assets/images/zulfah.jpeg"
import { RxHamburgerMenu } from "react-icons/rx"
import { LuSearch } from "react-icons/lu"
import InputField from "../shared-components/Input"
import { RiArrowDownWideFill } from "react-icons/ri"

const Header = ({ sideBarDetails, active, handleToggle }) => {
  return (
    <div className="flex w-full justify-between items-center ">
      <h1 className="text-[1.5rem] sm:text-[2rem] ">{sideBarDetails[active].title || ""}</h1>
      <button
        onClick={handleToggle}
        type="button"
        className="text-[#AFAFAF] text-[1.5rem] md:hidden order-3 "
      >
        <RxHamburgerMenu />
      </button>
      <div className=" items-center gap-x-[3rem] w-[50%] hidden sm:flex ">
        <div className="w-[65%] relative ">
          <div className="absolute top-[1rem] left-[0.5rem]">
            <LuSearch size={25} color="#ADADAD" />
          </div>
          <InputField
            className={"bg-[#F5F5F5] py-[1rem] px-[3rem] w-full"}
            placeholder="Search"
          />
        </div>
        <div className="flex items-center gap-x-[1rem] ">
          <div className="w-[3rem] h-[3rem] hidden md:block rounded-full overflow-hidden ">
            <img src={zulfah} alt="profile" className="max-w-full " />
          </div>
          <div className="space-y-[0.3rem] ">
            <div className="flex items-center gap-x-[0.75rem] text-[1rem] font-medium ">
              <h2>Oladapo</h2>
              <RiArrowDownWideFill />
            </div>
            <p className="text-[0.6rem] font-light ">Admin</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
