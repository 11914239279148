import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom" // Import useNavigate for navigation
import PageLayout from "../../common/pageLayout"
import signUp from "../../assets/images/sign-up.png"
import OtpInput from "react-otp-input"
import Button from "../../components/shared-components/Button"
import VerificationModal from "./verificationModal"
import useModalHooks from "../../common/hooks"

const Verify = () => {
  const [otp, setOtp] = useState("")
  const { modal, handleModal } = useModalHooks()
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(5)

  useEffect(() => {
    let timer
    if (modal) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer)
            handleModal()
            navigate("/login")
            return 0
          }
          return prevCountdown - 1
        })
      }, 1000)
    }

    return () => clearInterval(timer)
  }, [modal, handleModal, navigate])

  return (
    <>
      <PageLayout images={signUp}>
        <form className="w-full space-y-[1.2rem]">
          <h1 className="text-[2.5rem] font-bold">Verification</h1>
          <p className="text-[0.9rem] w-[18rem]">
            We have sent a four (4) digit code to your phone, kindly input it
            here
          </p>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span> </span>}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  width: "3rem",
                  height: "3rem",
                  margin: "0 auto",
                  borderRadius: "0.5rem",
                  textAlign: "center",
                  outline: "none",
                  border: "1px solid grey",
                }}
              />
            )}
          />
          <div>
            <Button
              text={"Verify"}
              onClick={handleModal}
              className={"text-white w-full"}
            />
          </div>
        </form>
      </PageLayout>
      {modal && <VerificationModal countdown={countdown} />}
    </>
  )
}

export default Verify
