import React from 'react'

const LandingInputField = ({field, ...props}) => {



    return (
        <input {...props} type={field === 'Date' ? "date" : "text"}  className="w-full block border-none outline-none" />
      
  )
}

export default LandingInputField
