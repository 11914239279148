import Button from "../Button"
import ShipmentCard from "./shipmentCard"
import packag from "../../../assets/images/package.png"
import { useState } from "react"
import renderInput from "./quoteInput/renderInput"
import { useToggleThrough } from "../../../Hooks/GlobalHook"

const GetQuote = () => {
  const details = [
    {
      status: "Pending shipments",
      value: "2",
      bg: "bg-secondary-100",
    },
    {
      status: "Delivered shipments",
      value: "10",
      bg: "bg-primary-100",
    },
    {
      status: "Cancelled shipments",
      value: "1",
      bg: "bg-primary-200",
    },
  ]

  const getQuotes = {
    Origin: "",
    Cargo: "",
    Destination: "",
    Date: "",
  }

  const [quotesInput, setQuotesInput] = useState(getQuotes)

  const handleInputChange = (e) => {
    setQuotesInput({ ...quotesInput, [e.target.name]: e.target.value })
  }

  const { setSelected } = useToggleThrough()

  return (
    <>
      <div className="lg:flex w-full gap-[1.5rem] space-y-[1.8rem] lg:space-y-0 ">
        <div className="w-full lg:w-[60%] flex flex-col justify-between gap-y-[1rem] ">
          <div className="lg:flex justify-between items-start w-full  rounded-[1.1rem] py-[1.5rem] px-[1.5rem] md:px-[2.5rem] bg-primary-300">
            <div>
              <h1 className="text-[2rem] lg:text-[2.3rem] text-white font-medium w-[19rem] md:w-[22rem] ">
                Seamless logistics starts here
              </h1>
              <div className="mt-[1.4rem] lg:mt-[3rem] ">
                <Button
                  text={"Lodge Shipment"}
                  className={" text-primary-300 bg-white"}
                  onClick={() => setSelected(1)}
                />
              </div>
            </div>
            <div className="flex justify-end mt-[1rem] ">
              <img src={packag} alt="package" className="max-w-full" />
            </div>
          </div>
          <div className="grid grid-cols-2  md:grid-cols-3 gap-[1rem] ">
            {details.map((item, index) => (
              <ShipmentCard
                key={index}
                status={item.status}
                value={item.value}
                bg={item.bg}
                index={index}
              />
            ))}
          </div>
        </div>
        <div className="w-full lg:w-[40%] rounded-[1.1rem] py-[1.3rem] px-[1.2rem] md:px-[2.3rem] bg-primary-100 ">
          <h2 className="text-[2rem] text-black font-medium ">Get Quotes</h2>
          <form action="" className="mt-[0.9rem] ">
            {Object.entries(quotesInput).map(([field, value], i) => (
              <div key={i}>
                {renderInput(field, value, i, handleInputChange)}
              </div>
            ))}
            <div className="mt-[1.4rem] ">
              <Button
                text={"Get Quotes"}
                className={"text-white bg-primary-500"}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default GetQuote
