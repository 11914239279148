import React from "react"

const InputField = ({ className, ...props }) => {
  return (
    <input
      {...props}
      className={`border-none h-[2.7rem] ${className}  rounded-[0.5rem] pt-[2rem] pb-[1.5rem] px-[1.5rem] w-full outline-none`}
    />
  )
}

export default InputField
