import worldwide from "../../../assets/images/worldwide.svg";
import trust from "../../../assets/images/trust.svg";
import tracking from "../../../assets/images/tracking.svg";
import blog1 from "../../../assets/images/blog1.svg";
import blog2 from "../../../assets/images/blog2.svg";
import blog3 from "../../../assets/images/blog3.svg";

export const CardDetails = [
  {
    icons: worldwide,
    title: "WORLDWIDE SERVICE",
    content:
      "Whatever the end destination for your cargo is, be sure that thanks to us it will arrive on time and even faster!",
  },
  {
    icons: trust,
    title: "TRUSTWORTHINESS",
    content:
      "Working in the industry for many years, our company trustworthiness has built us a solid reputation!",
  },
  {
    icons: tracking,
    title: "Parcel TRACKING",
    content:
      "We offer this service to track your cargo in real-time on a free basis. This is the perfect extra gift we can offer our clients!",
  },
];

export const BlogDetails = [
  {
    images: blog1,
    title: "WHAT YOU SHOULD KNOW WHEN REQUESTING AN OCEAN FREIGHT QUOTE",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation...",
    date: "3 min read   I   June 27, 2016",
  },
  {
    images: blog2,
    title: "THE OCEAN CONTAINER SHIPPING INDUSTRY CRISIS",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation...",
    date: "3 min read   I   June 27, 2016",
  },
  {
    images: blog3,
    title: "SHIFT OF FREIGHT FROM OCEAN CARRIERS TO FREIGHT FORWARDERS/NVOCCS",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation...",
    date: "3 min read   I   June 27, 2016",
  },
];

export const AccordionData = [
  {
    id: 1,
    title: "Section 1",
    content: "Content for section 1.",
  },
  {
    id: 2,
    title: "Section 2",
    content: "Content for section 2.",
  },
  {
    id: 3,
    title: "Section 3",
    content: "Content for section 3.",
  },
  {
    id: 4,
    title: "Section 4",
    content: "Content for section 4.",
  },
  {
    id: 5,
    title: "Section 5",
    content: "Content for section 5.",
  },
];
