const ProfileInput = ({ label, htmlFor, ...props }) => {
  return (
    <div className="relative mt-[0.75rem]">
      <label
        htmlFor={htmlFor}
        className="text-[0.7rem] text-[#797979] font-medium absolute top-3 left-4 "
      >
        {label}
      </label>
      <input
        type="text"
        {...props}
        className="w-full  rounded-[0.4rem] bg-[#F9F9F9] pt-[1.5rem] pb-[0.5rem] px-[1rem] border border-white  outline-none"
      />
    </div>
  )
}

export default ProfileInput
