import React from "react"
import Logo from "../../../components/shared-components/LogoComponent"
import Back from "../../../components/shared-components/backButton"

const PasswordResetLayout = ({ word, instruction, children }) => {
  return (
    <div className="bg-white flex flex-col justify-between max-w-full h-screen space-y-[3rem] p-[1rem] font-montserrat ">
      <div className="flex items-center justify-between w-[55%] ">
        <Back />
        <Logo />
      </div>
      <div className="flex justify-center">
        <div className="w-full lg:w-[40%]">
          <div className="text-center text-[#767676] ">
            {word ? (
              <p className="text-[1.25rem] ">
                That’s fine, We all forget things
              </p>
            ) : null}
            <h1 className="text-[3rem] text-black font-bold ">
              Password Reset
            </h1>
            <p className="text-[0.9rem] ">
              {instruction}
              
            </p>
          </div>
          {children}
        </div>
      </div>
      <div className="flex items-center justify-center text-center">
        <p className="">
          Copyright &copy;
          <script>document.write(new Date().getFullYear());</script>. All Rights
          Reserved. &mdash; Designed with love by{" "}
          <a href="https://zulfahgroup.com/">Zulfah</a>
        </p>
      </div>
    </div>
  )
}

export default PasswordResetLayout
