import { shipmentData } from "../../../components/data/optionData";
import Button from "../../../components/shared-components/Button";
import InputFields from "../../../components/shared-components/input-fields";
import Select from "../../../components/shared-components/select/Select";


const ShipmentDetails = () => {
  return (
    <>


        <div>
          <div className=" sm:flex block gap-[30px] justify-between items-center ">
            <div className="w-full">
              <Select
                selectName={""}
                htmlFor={""}
                label={"Choose Shipment Type"}
                selectValue={""}
                datas={shipmentData}
              />
              <InputFields
                htmlFor={"Phone"}
                label={"Name"}
                type={"text"}
                name={"phone_no"}
                className={``}
                divClassName={""}
                placeholder={"Enter Here"}
                pText={"Sender Information"}
              />
              <InputFields
                htmlFor={"Phone"}
                label={"Email"}
                type={"tel"}
                name={"phone_no"}
                className={``}
                placeholder={"Enter Here"}
              />

              <div className="w-full">
                <Select
                  selectName={""}
                  htmlFor={""}
                  label={"Service Options"}
                  selectValue={""}
                  datas={shipmentData}
                  pText={"Package Details"}
                />
                <Select
                  selectName={""}
                  htmlFor={""}
                  label={"Service Options"}
                  selectValue={""}
                  datas={shipmentData}
                />
              </div>
            </div>

            <div className="w-full ">
              <Select
                selectName={""}
                htmlFor={""}
                label={"Service Options"}
                selectValue={""}
                datas={shipmentData}
              />
              <InputFields
                htmlFor={"Phone"}
                label={"Name"}
                type={"text"}
                name={"phone_no"}
                className={``}
                placeholder={"Enter Here"}
                pText={"Recipient Information"}
              />

              <InputFields
                htmlFor={"Phone"}
                label={"Email"}
                type={"email"}
                name={"phone_no"}
                className={``}
                placeholder={"Enter Here"}
              />

              <div className="w-full my-[25px] pb-3 bg-white rounded-[10.39px] px-3">
                <label htmlFor="" className="text-[10.85px] text-[#E2E2E2] ">
                  {" "}
                  Description{" "}
                </label>
                <textarea
                  name=""
                  id=""
                  placeholder="Enter Here"
                  className="w-full h-[165px] outline-none"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap gap-[20px] pb-[40px] w-full">
            <Button text={"Save & Continue"} className={"text-white "} />
            <Button
              text={"Cancel"}
              className={"  !text-[#FF2424] !bg-[#FFD4D4] "}
            />
          </div>
        </div>
    </>
  );
};

export default ShipmentDetails;
