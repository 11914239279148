import React, { useState } from "react"
import Back from "../../components/shared-components/backButton"
import TBody from "../../components/shared-components/Table/TBody"
import Table from "../../components/shared-components/Table"
import { useParams } from "react-router-dom"
import { shipmentDetails } from "../Shipment/data"
import Button from "../../components/shared-components/Button"
import { IoIosArrowDown } from "react-icons/io"

const SingleShipment = () => {
  const { id } = useParams()

  const [action, setAction] = useState(false)

  const toggleAction = () => {
    setAction(!action)
  }

  const detail = shipmentDetails[id]

  // const detailOfEach = Object.values(detail)
  // console.log(detailOfEach)

  const resultOne = [
    {
      names: "Oladapo Koiki",
      shipment_ID: "4654746823",
      amount: "&#8358;456,090",
      date: "Mar. 20, 2023",
      type: "Air freight",
      status: "Pending",
    },
  ]
  const resultTwo = [
    {
      names: "Oladapo Koiki",
      shipment_ID: "4654746823",
      amount: "&#8358;456,090",
      date: "Mar. 20, 2023",
      type: "Air freight",
      status: "Completed",
    },
  ]

  const result = [
    { detail: "Shipment details", eachResult: resultOne },
    { detail: "Goods in shipment", eachResult: resultTwo },
  ]

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2 max-w-fit">
          <Back />
          <p className="font-medium ">
            Shipments ID:{" "}
            <span className=" text-[1rem] text-[#4A515C] font-normal ">
              4654746823
            </span>
          </p>
        </div>
        <div className="relative">
          <Button
            onClick={toggleAction}
            type={"button"}
            className={"flex-row-reverse text-white "}
            text={"Actions"}
            icon={<IoIosArrowDown />}
          />
          {action && (
            <ul className="text-[0.8rem] text-[#4A515C] rounded-[0.8rem] left-5 shadow-2xl bg-white space-y-[0.5rem] p-[1.2rem] absolute ">
              <li>Share</li>
              <li>Download invoice</li>
              <li className="cursor-pointer" onClick={toggleAction}>
                Cancel
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="mt-[1.5rem] ">
        <h1 className="text-[1.25rem]  ">Shipment Details</h1>
        <div className="grid grid-cols-6 text-center mt-[0.8rem] ">
          {/* {resultOne.map((data, i) => ( */}
          <div>
            <h2 className="font-bold py-[0.8rem] px-[2rem] bg-primary-100  ">
              Recipient
            </h2>
            <p>Oladapo Koiki</p>
          </div>
          <div>
            <h2 className="font-bold py-[0.8rem] px-[2rem] bg-primary-100  ">
              Tracking ID
            </h2>
            <p>4654746823</p>
          </div>
          <div>
            <h2 className="font-bold py-[0.8rem] px-[2rem] bg-primary-100  ">
              Amount
            </h2>
            <p>N456,090</p>
          </div>
          <div>
            <h2 className="font-bold py-[0.8rem] px-[2rem] bg-primary-100  ">
              Date
            </h2>
            <p>Mar. 20, 2023</p>
          </div>
          <div>
            <h2 className="font-bold py-[0.8rem] px-[2rem] bg-primary-100  ">
              Type
            </h2>
            <p>Sea freight</p>
          </div>
          <div>
            <h2 className="font-bold py-[0.8rem] px-[2rem] bg-primary-100  ">
              Status
            </h2>
            <p>Pending</p>
          </div>
          {/* ))} */}
        </div>
      </div>
    </div>
  )
}

export default SingleShipment
