import { createContext, useContext, useState } from "react"

const ToggleThrough = createContext()

export const useToggleThrough = () => {
  return useContext(ToggleThrough)
}

const GlobalHooks = ({ children }) => {
  const [selected, setSelected] = useState(null)

  return (
    <>
      <ToggleThrough.Provider value={{ selected, setSelected }}>
        {children}
      </ToggleThrough.Provider>
    </>
  )
}

export default GlobalHooks
