import React, { useState } from "react"
import PasswordResetLayout from "../PasswordResetLayout"
import Button from "../../../components/shared-components/Button"
import InputField from "../../../components/shared-components/Input"
import { PiEyeSlashThin, PiEyeThin } from "react-icons/pi"
import { usePassword } from "../../../common/eyeToggle"
import { useNavigate } from "react-router-dom"
import { loginPathName } from "../../../components/routes/pathName"

const NewPassword = () => {

  const navigate = useNavigate()

  const [password, setPassword] = useState("")
  function handlePasswordChange(e) {
    setPassword(e.target.value)
  }
  const { showPassword, togglePasswordVisibility } = usePassword()
  const [confirmShowPassword, setConfirmShowPassword] = useState(false)

  function submit(e) {
    e.preventDefault()

    navigate(loginPathName)
  }

  return (
    <PasswordResetLayout
      instruction={"Enter a password you can always remember"}
    >
      <form className="mt-[2rem] space-y-[2rem]" onClick={submit}>
        <div className="relative">
          <InputField
            placeholder="Enter password"
            className={"bg-[#F7F7F7] "}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
          />
          <div
            className="absolute top-5 right-2 cursor-pointer text-[1.5rem]"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <PiEyeThin /> : <PiEyeSlashThin />}
          </div>
        </div>
        <div className="relative">
          <InputField
            placeholder="Confirm password"
            className={"bg-[#F7F7F7] "}
            type={confirmShowPassword ? "text" : "password"}
          />
          <div
            className="absolute top-5 right-2 cursor-pointer text-[1.5rem]"
            onClick={() => setConfirmShowPassword(!confirmShowPassword)}
          >
            {confirmShowPassword ? <PiEyeThin /> : <PiEyeSlashThin />}
          </div>
        </div>

        <div className="">
          <Button text={"Update password"} className={"text-white w-full"} />
        </div>
      </form>
    </PasswordResetLayout>
  )
}

export default NewPassword
