import React, { useState } from "react"
import Back from "../../components/shared-components/backButton"
import Button from "../../components/shared-components/Button"
import { FaPlus } from "react-icons/fa"
import { LuSearch } from "react-icons/lu"
import ShipmentTable from "./shipmentTable"


const Shipment = () => {
  
  const shipmentTabs = ["All", "Completed", "Pending", "Cancelled"]
  const [currentShipment, setCurrentShipment] = useState(0)

  const handleClick = (index) => setCurrentShipment(index)

  return (
    <div className="w-full">
      <div className="flex items-center gap-2">
        <Back />
        <p>Shipment</p>
      </div>

      <div className="flex flex-col lg:flex-row lg:items-center justify-between mt-[1rem] space-y-4 lg:space-y-0 ">
        <ul className="flex gap-x-[1.2rem] text-[1rem] font-medium justify-center items-center bg-[#F9F9F9] py-[16px] px-[1rem] md:px-[42.5px] rounded-[8px]">
          {shipmentTabs.map((tab, index) => (
            <li
              key={index}
              className={`cursor-pointer ${currentShipment === index ? "text-primary-300 border-b-2 border-primary-300" : ""}`}
              onClick={() => handleClick(index)}
            >
              {tab}
            </li>
          ))}
        </ul>

        <div className="flex flex-col md:flex-row justify-between md:items-center gap-[1rem]">
          <form>
            <div className="relative">
              <div className="absolute top-[1rem] left-[0.5rem]">
                <LuSearch size={25} color="#ADADAD" />
              </div>
              <input
                type="text"
                placeholder="Search"
                className="text-[1rem] bg-[#F5F5F5] lg:text-[20px] placeholder:text-[#ADADAD] font-medium py-[16px] px-[42.5px] items-center gap-5 rounded-[8px] w-full md:w-fit outline-none"
              />
            </div>
          </form>
          <div>
            <Button text={"Lodge Shipment"} icon={<FaPlus size={15} />} className={"text-white w-full md:w-fit "} />
          </div>
        </div>
      </div>

      <div className="text-[#2C2C2C] mt-[1.25rem]">
        <h1 className="text-[1.25rem]">{shipmentTabs[currentShipment]}</h1>
        <ShipmentTable status={shipmentTabs[currentShipment]} />
      </div>
    </div>
  )
}

export default Shipment
