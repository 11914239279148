import React, { useState } from "react";
import { BsToggleOn, BsToggleOff } from "react-icons/bs";

const Notification = () => {
  const [toggles, setToggles] = useState([true, true]);

  const handleToggle = (index) => {
    setToggles(toggles.map((toggle, i) => (i === index ? !toggle : toggle))
    );
  };

  return (
    <div className="space-y-[1.2rem] ">
      {[...Array(2)].map((_, i) => (
        <div key={i} className="flex items-start gap-x-[0.8rem] ">
          <button
            onClick={() => handleToggle(i)}
            className="text-[2rem] text-primary-300 outline-none border-none"
          >
            {toggles[i] ? <BsToggleOn /> : <BsToggleOff />}
          </button>
          <div className="text-[1.1rem] sm:text-[1.5rem] space-y-[0.4rem] text-[#2C2C2C] ">
            <h1>Send email notifications to sender</h1>
            <p className="text-[0.9rem] sm:text-[1rem] sm:w-[55%] ">
              Receives a notification when shipment is created, confirmed, in
              transit and delivered
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Notification;
