import React from "react"
import InputField from "../../components/shared-components/Input"
import { PiEyeSlashThin, PiEyeThin } from "react-icons/pi"
import { usePassword } from "../../common/eyeToggle"



const RenderInput = (field, value, handleChange) => {
  const { showPassword, togglePasswordVisibility } = usePassword()

  if (field === "password") {
    return (
      <div className="relative">
        <InputField
          type={showPassword ? "text" : "password"}
          placeholder={field}
          value={value}
          name={field}
          onChange={handleChange}

          className="bg-[#F7F7F7] lg:bg-white"
        />
        <div
          className="absolute top-5 right-2 cursor-pointer text-[1.5rem]"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <PiEyeThin /> : <PiEyeSlashThin />}
        </div>
      </div>
    )
  } else {
    return (
      <InputField
        type="text"
        placeholder={field}
        value={value}
        name={field}
        onChange={handleChange}
        className="bg-[#F7F7F7] lg:bg-white"
      />
    )
  }
}

export default RenderInput
