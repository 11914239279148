import React from "react"
import GetQuote from "../../components/shared-components/getQuote"
import Table from "../../components/shared-components/Table"
import TBody from "../../components/shared-components/Table/TBody"
import { tableDetails } from "./datas"
import { Link } from "react-router-dom"
import { shipmentPathName } from "../../components/routes/pathName"
import SendingParcels from "../SendingParcel"
import { useToggleThrough } from "../../Hooks/GlobalHook"

const Dashboard = () => {

  const {selected} = useToggleThrough()

  return (
    <>
      <div className="">
        <h1 className="text-[1.25rem] font-Raleway ">
          Welcome, <strong>Olawale!</strong>
        </h1>
        <div className="mt-[1.13rem] ">
          <GetQuote />
        </div>
        <div className="mt-[1.9rem]  ">
          <caption className="flex justify-between text-[1.25rem] text-black font-normal">
            <span>Recent Shipments</span>
            <Link to={shipmentPathName}>
              <button
                type="button"
                className="text-primary-300 underline cursor-pointer"
              >
                See All
              </button>
            </Link>
          </caption>
          <div className="overflow-x-auto">
            <Table
              datas={[
                "Name",
                "Shipment ID",
                "Amount",
                "Date",
                "Type",
                "Status",
              ]}
            >
              <TBody tableDatas={tableDetails} />
            </Table>
          </div>
        </div>
      </div>
      {selected !== null && <SendingParcels />}
    </>
  )
}

export default Dashboard
