import React from "react"
import InputField from "../../components/shared-components/Input"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

const commonInputStyles = {
  containerStyle: {
    width: "100%",
  },
  inputStyle: {
    height: "2.7rem",
    backgroundColor: "white",
    paddingTop: "2rem",
    paddingBottom: "1.5rem",
    paddingLeft: "3.5rem",
    paddingRight: "1.5rem",
    width: "100%",
    borderRadius: "0.5rem",
    outline: "none",
    border: "none",
  },
  buttonStyle: {
    backgroundColor: "white",
    border: "none",
    outline: "none",
    width: "10%",
  },
}

const RenderInput = (field, value, handleChange, accountTypeDetails) => {
  if (field === "AccountType") {
    return (
      <select
        value={value}
        name={field}
        onChange={(e) => handleChange(e.target.value, e)}
        className="text-black border-none h-[2.7rem] bg-[#F7F7F7] lg:bg-white rounded-[0.5rem] pt-[2rem] pb-[1.5rem] px-[1.5rem] w-full outline-none"
      >
        <option value="" className="text-black">Select account type...</option>
        {accountTypeDetails.map((data, i) => (
          <option key={i} value={data}>
            {data}
          </option>
        ))}
      </select>
    )
  } else if (field === "Phone") {
    return (
      <PhoneInput
        country={"ng"}
        value={value}
        onChange={(value, country, e) => handleChange(value, null, "Phone")}
        inputProps={{
          name: field,
          required: true,
          autoFocus: true,
        }}
        containerStyle={commonInputStyles.containerStyle}
        inputStyle={commonInputStyles.inputStyle}
        buttonStyle={commonInputStyles.buttonStyle}
      />
    )
  } else {
    return (
      <InputField
        type="text"
        placeholder={field}
        value={value}
        name={field}
        onChange={(e) => handleChange(e.target.value, e)}
        className="bg-[#F7F7F7] lg:bg-white"
      />
    )
  }
}

export default RenderInput
