import React from "react"
import Logo from "../shared-components/LogoComponent"
import { Link } from "react-router-dom"
import { MdCancel } from "react-icons/md"

const SideBar = ({ sideBarDetails, active, handleActive, handleToggle }) => {
  return (
    <div className="flex flex-col w-full md:h-full drop-shadow-xl md:items-center fixed md:static bg-white z-50 ">
      <div className="w-full md:w-[70%]  my-[3rem] px-[2rem] md:px-0 ">
        <div
          className="flex justify-end bg-white text-[#AFAFAF] text-[1.5rem] md:hidden cursor-pointer"
          onClick={handleToggle}
        >
          <MdCancel />
        </div>
        <div className="w-full">
          <Logo />
        </div>
        <div className="flex flex-col gap-y-[0.8rem] mt-[2.5rem]">
          {sideBarDetails.map((data, i) => (
            <Link
              key={i}
              className={`flex gap-x-[1.3rem] w-full ${
                active === i
                  ? "text-primary-500 bg-primary-100"
                  : "text-[#777777] bg-white "
              }  rounded-[0.5rem] py-[0.8rem] px-[0.5rem] md:w-fit lg:w-full hover:bg-primary-100 cursor-pointer`}
              onClick={() => handleActive(i)}
              to={data.path}
            >
              <div className="md:hidden lg:block ">
                <data.icon fill={active === i ? "#0E94CD" : "#777777"} />
              </div>
              <p className="md:text-[0.8rem] lg:text-[1rem] " >{data.title}</p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SideBar
