import Dashboard from "../../pages/Dashboard";
import Landing from "../../pages/Landing";
import Login from "../../pages/Login/lndex";
import SendingParcels from "../../pages/SendingParcel";
import PasswordReset from "../../pages/PasswordReset";
import NewPassword from "../../pages/PasswordReset/NewPassword";
import Settings from "../../pages/Settings";
import Shipment from "../../pages/Shipment";
import SignUp from "../../pages/SignUp";
import SingleShipment from "../../pages/SinglesingleShipment";
import Verify from "../../pages/Verify";
import { dashboardPathName, landingPathName, loginPathName, newPasswordResetPathName, sendingParcels, passwordResetPathName, settingsPathName, shipmentPathName, signUpPathName, verifyPathName, singleShipmentPathName} from "./pathName";



export const pageRoute = [
    {
        path: landingPathName,
        element: Landing,
        layout: false,

    },
    {
        path: dashboardPathName,
        element: Dashboard,
        layout: true,
    },
    {
        path: loginPathName,
        element: Login,
        layout: false,

    },
    {
        path: signUpPathName,
        element: SignUp,
        layout: false,

    },
    {
        path: shipmentPathName,
        element: Shipment,
        layout: true,

    },
    {
        path: settingsPathName,
        element: Settings,
        layout: true,

    },
    {

        path: singleShipmentPathName,
        element: SingleShipment,
        layout: true,

    },
  {
        path: sendingParcels,
        element: SendingParcels,
       layout: false,
    },
      {
        path: verifyPathName,
        element: Verify,
        layout: false,

    },
    {
        path: passwordResetPathName,
        element: PasswordReset,
        layout: false,

    },
    {
        path: newPasswordResetPathName,
        element: NewPassword,
        layout: false,

    },
]