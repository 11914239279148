const ConfirmationContent = ({ divClassName, fH1Text, sH1Text }) => {
  return (
    <>
      <div
        className={`border-b-[1px] border-[#DFDFDF]  py-[15px] basis-[50%] ${divClassName}`}
      >
        <h1 className="text-[12px] sm:text-[16px] text-[#979797] ">{fH1Text}</h1>
        <h1 className="text-[16px] sm:text-[20px]">{sH1Text} </h1>
      </div>
    </>
  );
};

export default ConfirmationContent;
