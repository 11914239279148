import React from "react"
import Logo from "../../../components/shared-components/LogoComponent"
import verify from "../../../assets/images/verify.svg"
import useModalHooks from "../../../common/hooks"

const VerificationModal = ({ countdown }) => {
  const { handleModal } = useModalHooks()

  function closeModal(e) {
    e.target.id === "close" && handleModal()
  }
  return (
    <div
      id="close"
      onClick={closeModal}
      className=" flex justify-center md:items-center text-[#2C2C2C] fixed top-0 w-full h-screen bg-black bg-opacity-60 font-montserrat "
    >
      <div className="bg-white w-full md:w-[50%] flex flex-col items-center gap-y-[3rem] p-[1rem] rounded-lg ">
        <p className="text-sm text-gray-600 text-end">
          Redirected in <span className="font-bold">{countdown}</span> seconds.
        </p>
        <div className="">
          <Logo />
        </div>
        <div className="">
          <div className="flex justify-center items-center border-[#D9F4FF] bg-[#D9F4FF] w-[18rem] h-[18rem] rounded-full ">
            <div className="flex justify-center items-center border-[#8DDDFF] bg-[#8DDDFF] w-[15rem] h-[15rem] rounded-full ">
              <div className="flex justify-center items-center border-[#0E94CD] bg-[#0E94CD] w-[12rem] h-[12rem] rounded-full ">
                <img src={verify} alt="verify" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[14rem] text-center ">
          <h1 className="text-[1.25rem] font-medium ">SUCCESS!</h1>
          <p>Your seamless logistic experience starts now</p>
        </div>
      </div>
    </div>
  )
}

export default VerificationModal
