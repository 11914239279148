import { useEffect, useState } from "react"
import { useToggleThrough } from "../../Hooks/GlobalHook"
import Confirmation from "./component/confirmation"
import Schedule from "./component/schedule"
import ShipmentDetails from "./component/shipment-details"
import Heading from "./component/headings"
import CloseComponent from "./component/close-component"
import ToogleThroughGroup from "./component/toogle-through-group"

const SendingParcels = () => {
  const [selectedDetail, setSelectedDetail] = useState(1)
  const { setSelected } = useToggleThrough()
  const headingH1 = ["Lodge Shipment", "Lodge Shipment", "Review Order"]

  const close = (e) => {
    if (e.target.id === "close") {
      setSelected(null)
    }
  }

  useEffect(() => {
    if (selectedDetail !== 0) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [selectedDetail])

  return (
    <>
      <div className="sm:bg-[#000000] bg-[#F7F7F7] flex w-full h-screen justify-center items-center sm:bg-opacity-35 cursor-pointer fixed top-0 left-0 z-50 overflow-y-auto">
        <div
          id="close"
          onClick={close}
          className="max-h-full w-full flex justify-center items-start overflow-y-auto md:p-[20px]"
        >
          <div
            className={`relative w-full sm:w-[1238px] bg-[#F7F7F7] ${
              selectedDetail === 3 ? "sm:max-w-[711px]" : "sm:my-[157px]"
            } rounded-[20.33px] mx-auto px-[10px] sm:px-[69.38px]`}
          >
            <Heading
              headingH1={headingH1[selectedDetail - 1]}
              className="pt-[100px] pb-[50px]"
            />
            <CloseComponent onClick={() => setSelected(null)} />

            <ToogleThroughGroup
              selectedDetail={selectedDetail}
              setSelectedDetail={setSelectedDetail}
            />
            {selectedDetail === 1 && <ShipmentDetails />}
            {selectedDetail === 2 && <Schedule />}
            {selectedDetail === 3 && <Confirmation />}
          </div>
        </div>
      </div>
    </>
  )
}

export default SendingParcels
