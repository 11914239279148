import ToogleThrough from "./toogle-through"

const ToogleThroughGroup = ({ selectedDetail, setSelectedDetail }) => {
  return (
    <>
      <div className="pb-[50px] pt-[40px]">
        <div className="flex flex-wrap gap-2">
          <ToogleThrough
            h3Text={"Shipment Details"}
            className={` sm:w-[200px]  ${
              selectedDetail === 1 ? "!border-[#0E94CD] " : "!border-[#979797]"
            }`}
            onClick={() => {
              setSelectedDetail(1)
            }}
            h3ClassName={`${
              selectedDetail === 1 ? "!text-[#0E94CD] " : "!text-[#979797]"
            }`}
          />
          <ToogleThrough
            h3Text={"Schedule"}
            className={` sm:w-[110px] ${
              selectedDetail === 2 ? "!border-[#0E94CD] " : "!border-[#979797]"
            }`}
            onClick={() => {
              setSelectedDetail(2)
            }}
            h3ClassName={`${
              selectedDetail === 2 ? "!text-[#0E94CD] " : "!text-[#979797]"
            }`}
          />

          <ToogleThrough
            h3Text={"Confirmation"}
            className={`sm:w-[160px] ${
              selectedDetail === 3 ? "!border-[#0E94CD] " : "!border-[#979797]"
            }`}
            onClick={() => {
              setSelectedDetail(3)
            }}
            h3ClassName={`${
              selectedDetail === 3 ? "!text-[#0E94CD] " : "!text-[#979797]"
            }`}
          />
        </div>
      </div>
    </>
  )
}

export default ToogleThroughGroup
