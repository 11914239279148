import Button from "../../../components/shared-components/Button"
import ConfirmationContent from "./confirmation-content"

const Confirmation = () => {
  return (
    <>
      <h1 className="text-[24px] text-[#979797] font-[400] font-montserrat">
        Review Order
      </h1>

      <div className=" sm:max-w-[577px] w-full bg-white p-[20px] font-montserrat rounded-[20.33px] my-[25px]">
        <div className=" block sm:flex justify-between gap-[20px]">
          <div className="w-full">
            <ConfirmationContent
              fH1Text={"Shipment Type"}
              sH1Text={"International"}
            />
            <ConfirmationContent
              fH1Text={"Sender Information"}
              sH1Text={"Oladapo Koiki +2348029386768 okoiki@gmail.com"}
            />
            <ConfirmationContent
              fH1Text={"Package Details"}
              sH1Text={"Grocery, 15kg"}
            />

            <ConfirmationContent
              fH1Text={"Pick-up Details"}
              sH1Text={"20th July, 2024, 7pm WAT"}
              divClassName={"border-none"}
            />
          </div>

          <div className="w-full">
            <ConfirmationContent
              fH1Text={"Service Option"}
              sH1Text={"Express"}
            />

            <ConfirmationContent
              fH1Text={"Sender Information"}
              sH1Text={"Oladapo Koiki +2348029386768 okoiki@gmail.com"}
            />

            <ConfirmationContent
              fH1Text={"Description"}
              sH1Text={"Lorem ipsium"}
            />

            <ConfirmationContent
              fH1Text={"Drop-off location"}
              sH1Text={"New York, USA"}
              divClassName={"border-none"}
            />
          </div>
        </div>
      </div>

      <div className="block  sm:flex flex-wrap justify-between gap-[5px] py-[70px] space-y-5 sm:space-y-0">
        <Button
          text={"Download confirmation"}
          className={
            "text-[20px] text-white w-full sm:w-[300px] !sm:px-[20.94px] !sm:py-[19.24px]"
          }
        />
        <Button
          text={"Contact support"}
          className={
            "text-[20px] !text-[#0E94CD] w-full sm:w-[260px] !bg-[#C1ECFF] !px-[55.94px] !py-[19.24px]"
          }
        />
      </div>
    </>
  )
}

export default Confirmation
