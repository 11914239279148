import { useState } from "react"

export const usePassword = () => {
    const [showPassword, setShowPassword] = useState(false)
  
    function togglePasswordVisibility() {
      setShowPassword((prevShowPassword) => !prevShowPassword)
    }
  
    return { showPassword, togglePasswordVisibility }
  }